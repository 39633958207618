import Link from "next/link";

import { formatDate } from "../../services/format";

function StatusBanner({ pendingReviewCount }) {
  if (pendingReviewCount === 0) {
    return <></>;
  }

  const label = `Image${pendingReviewCount > 1 ? "s" : ""} in review`;

  return <div className="truck-card__status">{label}</div>;
}

export default function TruckCard({
  approvedCount,
  assetUrls = [],
  lastUpdated,
  lastUpdatedBy,
  make,
  model,
  pendingReviewCount,
  retakeRequiredCount,
  vin,
  stockNumber,
  isAppraisal,
  appraisalStatus,
  year,
}) {
  return (
    <Link href={`/truck/${vin}`}>
      <a className="truck-card">
        <div className="truck-card__image">
          <StatusBanner pendingReviewCount={pendingReviewCount} />

          <img
            className="truck-card__photo"
            src={`${process.env.NEXT_PUBLIC_ASSETS_ENDPOINT}${assetUrls[0]}?w=300`}
            alt=""
            width="300"
          />
        </div>

        <div className="truck-card__info">
          <div className="truck-card__header">
            <h3 className="truck-card__id">{stockNumber}</h3>

            <div className="truck-card__details">
              {year} {make} {model}
            </div>
          </div>

          <div className="truck-card__count">
            {approvedCount} approved images
          </div>

          <div className="truck-card__count">
            {pendingReviewCount} images in review
          </div>

          <div className="truck-card__count">
            {retakeRequiredCount} images require retake
          </div>

          {isAppraisal && appraisalStatus === 'Appraisal Requested' && (
            <div className="truck-card__count">
              <p style={{color: "red", fontWeight: 'bold'}}>This truck is in for appraisal</p>
            </div>
          )}

          <div className="truck-card__last-updated">
            Last updated <strong>{formatDate(lastUpdated)}</strong> by{" "}
            <strong>{lastUpdatedBy}</strong>
          </div>
        </div>
      </a>
    </Link>
  );
}
