import { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import Layout from "../components/Layout";
import RecentTrucks from "../components/RecentTrucks";
import RequiresApproval from "../components/RequiresApproval";
import SearchBar from "../components/SearchBar";

import { useRoles } from "../hooks/useRoles";
import { useTruckCheck } from "../hooks/useTruckCheck";

function NoResults() {
  const { rolesMap, checkAccess } = useRoles();

  return (
    <div className="search-form__no-results">
      <p>
        No results found for that stock number. Please check the number and try
        again
        {!checkAccess([rolesMap.salesSap]) && (
          <>
            , or{" "}
            <Link href="/truck/new">
              <a className="link--underline">add a new truck</a>
            </Link>
          </>
        )}
        .
      </p>
    </div>
  );
}

export default function Home() {
  const { rolesMap, checkAccess } = useRoles();
  const { isLoading, checkForTruck } = useTruckCheck();

  const router = useRouter();

  const [showNoResults, setShowNoResults] = useState(false);

  const clearError = useCallback(() => {
    setShowNoResults(false);
  }, [setShowNoResults]);

  const submitSearch = useCallback(
    async (e, searchText) => {
      e.preventDefault();

      if (router.query && router.query.truckId) {
        router.replace(router.asPath, router.pathname, { shallow: true });
      }

      const checkResults = await checkForTruck(searchText);

      if (checkResults?.isActive) {
        setShowNoResults(false);

        router.push(`/truck/${searchText}`);
      } else {
        setShowNoResults(true);
      }
    },
    [checkForTruck, router]
  );

  useEffect(() => {
    if (router.query && router.query.truckId) {
      setShowNoResults(true);
    }
  }, [router.query]);

  return (
    <Layout headerSearch={false}>
      <div className="container">
        <div className="search">
          <h1 className="header--lvl-1">
            Truck Inventory and Service Image Manager
          </h1>

          <div className="search-form">
            <div className="search-form__section search-form__section--input">
              <SearchBar
                onSubmit={submitSearch}
                isLoading={isLoading}
                isError={showNoResults}
                clearError={clearError}
              />
            </div>

            {showNoResults && <NoResults />}
          </div>
        </div>
      </div>

      {checkAccess([rolesMap.admin, rolesMap.marketing]) && (
        <RequiresApproval />
      )}

      <RecentTrucks />
    </Layout>
  );
}
