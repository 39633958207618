import { useEffect, useState } from 'react';

import { fetchRecent } from '../services/truckAssets';

function useRecent() {
	const [ assets, setAssets ] = useState( [] );
	const [ loading, setLoading ] = useState( false );
	const [ error, setError ] = useState( null );

	useEffect( () => {
		( async() => {
			setLoading( true );

			try {
				const recentResponse = await fetchRecent();

				setAssets( recentResponse );
			} catch( err ) {
				console.log( err );

				setError( 'There was an error finding your recent trucks' );
			} finally {
				setLoading( false );
			}
		})();
	}, [] );

	return {
		assets,
		loading,
		error
	};
}

export { useRecent };
