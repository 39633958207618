import Loading from 'react-loading';

import TruckCard from '../TruckCard';

import { useRecent } from '../../hooks/useRecent';

function SectionLoading() {
	return (
		<div className="container">
			<Loading className="page-loader" type="spin" color="#676767" width={ 32 } height={ 32 } />
		</div>
	);
}

function RecentTrucksList({ assets, error }) {
	if ( error ) {
		return <>{ error }</>;
	}

	if ( assets.length === 0 ) {
		return <>No recently updated trucks found.</>;
	}

	return (
		<div className="recent-trucks__list">
			{ assets.map( ( truck, idx ) => (
				<TruckCard { ...truck } key={ idx } />
			))}
		</div>
	);
}

export default function RecentTrucks() {
	const { loading, assets, error } = useRecent();

	return (
		<div className="recent-trucks  container">
			<h2 className="header--lvl-2">My recent trucks</h2>

			{ loading && <SectionLoading /> }

			{ !loading && <RecentTrucksList assets={ assets } error={ error } /> }
		</div>
	);
}
