import Loading from 'react-loading';

import TruckCard from '../TruckCard';

import { useNeedsReview } from '../../hooks/useNeedsReview';

function SectionLoading() {
	return (
		<div className="container">
			<Loading className="page-loader" type="spin" color="#676767" width={ 32 } height={ 32 } />
		</div>
	);
}

function TrucksNeedingApproval({ assets, error }) {
	if ( error ) {
		return <>{ error }</>;
	}

	if ( assets.length === 0 ) {
		return <>There are no trucks requiring approval at this time.</>;
	}

	return (
		<div className="requires-approval__trucks">
			{ assets.map( ( asset, idx ) => (
				<TruckCard { ...asset } key={ idx } />
			))}
		</div>
	);
}

export default function RequiresApproval() {
	const { loading, assets, error } = useNeedsReview();

	return (
		<div className="full-width">
			<div className="full-width__inner">
				<div className="requires-approval">
					<h2 className="header--lvl-2">Requires image review and approval { assets.length > 0 && <>({ assets.length })</> }</h2>

					{ loading && <SectionLoading /> }

					{ !loading && <TrucksNeedingApproval assets={ assets } error={ error } /> }
				</div>
			</div>
		</div>
	);
}
